import { http, HttpResponse } from 'msw';

const hubspot = [
  http.post('/api/contacts/search', () =>
    HttpResponse.json({ id: 'some-uuid', message: 'found the contact' })
  ),
  http.patch('/api/contacts/:hubspotID', () =>
    HttpResponse.json({ message: 'updated the customer' })
  ),
  http.post('/api/contacts', () =>
    HttpResponse.json({ message: 'created the customer' })
  )
];

export default hubspot;
