/**
 * Defines how many items fit in a single bin
 */
export const BIN_CAPACITY_MAP = {
  hammers: 11,
  sneakers: 4,
  toothpaste: 300,
  headphones: 18,
  soap: 30,
  tennisballs: 216,
  cokeCans: 86,
  shirts: 50,
  wineBottles: 21,
  phones: 100
};

export const DEFAULT_ORDERLINES = 2;

export const DEFAULT_SEO_META = {
  title: 'Pio',
  description: 'Lorem ipsum dolor sit amet',
  url: 'https://pio.com',
  siteName: 'Pio',
  image: 'https://pio.com/images/og-image.jpg'
};

export const SUBMENU_PATHS = [
  {
    label: 'Overview',
    path: '/overview'
  },
  {
    label: 'Components',
    path: '/components/bins'
  },
  {
    label: 'Installation',
    path: '/installation'
  },
  {
    label: 'Integration',
    path: '/integration'
  }
];

export const NAVITEM_ANIMATION = {
  closed: {
    opacity: 0,
    x: 10,
    transition: { delay: 0.2 }
  },
  open: { opacity: 1, x: 0 }
};

export const NAV_ANIMATION = {
  closed: {
    opacity: 0,
    transition: {
      type: 'easeInOut',
      duration: 0.2,
      staggerChildren: 0,
      staggerDirection: 1
    }
  },
  open: {
    opacity: 1,
    transition: {
      type: 'easeInOut',
      duration: 0.2,
      staggerChildren: 0.2,
      staggerDirection: 1
    }
  }
};

export const VIDEO_CONTROLS_ANIMATION = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3
    }
  },
  hidden: {
    opacity: 0,
    y: 15,
    transition: {
      duration: 0.3
    }
  }
};

export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_STANDARD = 1024;

export const SANITY_TTL = 60 * 5; // Revalidate after every 5 minutes
