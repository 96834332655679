import cx from 'classnames';
import React from 'react';

import { ICONS } from './icons';
import styles from './styles.module.scss';

export type IconTypes = keyof typeof ICONS;
export type IconSize =
  | 'xs'
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | 'xxl'
  | 'text-align'
  | 'none';
export type IconFill =
  | 'primary'
  | 'secondary'
  | 'inactive'
  | 'white'
  | 'zest'
  | 'navy'
  | 'dark'
  | 'grey';
export type IconDirection = 'right' | 'left' | 'up' | 'down';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconTypes;
  /**
   * Icon size corresponds to following pixel size
   * @example
   * xs: 16px
   * s: 24px
   * m: 32px
   * l: 48px
   * xl: 72px
   * xxl: 96px
   */
  size?: IconSize;
  fill?: IconFill;
  direction?: IconDirection;
  inactive?: boolean;
  specialFill?: boolean; // Used to alter how we change color
}

const Icon: React.FC<IconProps> = ({
  name,
  size = 'm',
  fill,
  direction,
  className,
  inactive,
  specialFill = false,
  ...rest
}) => {
  const Component = ICONS[name];
  return (
    <Component
      className={cx(
        className,
        styles.icon,
        fill && styles[fill],
        direction && styles[direction],
        {
          [styles[size]]: size !== 'none',
          [styles.specialFill]: specialFill
        }
      )}
      inactive={inactive}
      {...rest}
    />
  );
};

export default Icon;
