import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface RocketPopupState {
  closed: boolean;
  setClosed: (closed: boolean) => void;
}

const useRocketPopupStore = create<
  RocketPopupState,
  [['zustand/persist', RocketPopupState]]
>(
  persist(
    set => ({
      closed: false,
      setClosed: closed => set({ closed })
    }),
    {
      name: 'pio-rocket-popup'
    }
  )
);

export default useRocketPopupStore;
