// AUTO-GENERATED using process-icons.js
// run "node process-icons" in root
import IconAlert from '@icons/alert.svg';
import IconArrowLeft from '@icons/arrow-left.svg';
import IconArrowRight from '@icons/arrow-right.svg';
import IconAutostore from '@icons/autostore.svg';
import IconBurger from '@icons/burger.svg';
import IconCamera from '@icons/camera.svg';
import IconChevronDown from '@icons/chevron-down.svg';
import IconChevronLeft from '@icons/chevron-left.svg';
import IconChevronRight from '@icons/chevron-right.svg';
import IconChevronUp from '@icons/chevron-up.svg';
import IconClose from '@icons/close.svg';
import IconCreatedByAutostore from '@icons/created-by-autostore.svg';
import IconDiamond from '@icons/diamond.svg';
import IconEmail from '@icons/email.svg';
import IconEye from '@icons/eye.svg';
import IconFeather from '@icons/feather.svg';
import IconFootprint from '@icons/footprint.svg';
import IconGreen from '@icons/green.svg';
import IconHeight from '@icons/height.svg';
import IconInfo from '@icons/info.svg';
import IconInjury from '@icons/injury.svg';
import IconLink from '@icons/link.svg';
import IconMeasurements from '@icons/measurements.svg';
import IconMeasurementsDisabled from '@icons/measurements-disabled.svg';
import IconMinus from '@icons/minus.svg';
import IconMobile from '@icons/mobile.svg';
import IconNotification from '@icons/notification.svg';
import IconPhones from '@icons/phones.svg';
import IconPick from '@icons/pick.svg';
import IconPio from '@icons/pio.svg';
import IconPlay from '@icons/play.svg';
import IconPlus from '@icons/plus.svg';
import IconPrinter from '@icons/printer.svg';
import IconProfile from '@icons/profile.svg';
import IconProfileGap from '@icons/profile-gap.svg';
import IconRecycle from '@icons/recycle.svg';
import IconRedo from '@icons/redo.svg';
import IconReset from '@icons/reset.svg';
import IconRobot from '@icons/robot.svg';
import IconRotate from '@icons/rotate.svg';
import IconScale from '@icons/scale.svg';
import IconSend from '@icons/send.svg';
import IconShirt from '@icons/shirt.svg';
import IconShoes from '@icons/shoes.svg';
import IconShuffle from '@icons/shuffle.svg';
import IconSpeed from '@icons/speed.svg';
import IconStack from '@icons/stack.svg';
import IconStore from '@icons/store.svg';
import IconThreeQuarterCircle from '@icons/three-quarter-circle.svg';
import IconTick from '@icons/tick.svg';
import IconUndo from '@icons/undo.svg';
import IconVideo from '@icons/video.svg';

export const ICONS = {
  alert: IconAlert,
  arrowLeft: IconArrowLeft,
  arrowRight: IconArrowRight,
  autostore: IconAutostore,
  burger: IconBurger,
  camera: IconCamera,
  chevronDown: IconChevronDown,
  chevronLeft: IconChevronLeft,
  chevronRight: IconChevronRight,
  chevronUp: IconChevronUp,
  close: IconClose,
  createdByAutostore: IconCreatedByAutostore,
  diamond: IconDiamond,
  email: IconEmail,
  eye: IconEye,
  feather: IconFeather,
  footprint: IconFootprint,
  green: IconGreen,
  height: IconHeight,
  info: IconInfo,
  injury: IconInjury,
  link: IconLink,
  measurements: IconMeasurements,
  measurementsDisabled: IconMeasurementsDisabled,
  minus: IconMinus,
  mobile: IconMobile,
  pio: IconPio,
  notification: IconNotification,
  phones: IconPhones,
  pick: IconPick,
  play: IconPlay,
  plus: IconPlus,
  printer: IconPrinter,
  profileGap: IconProfileGap,
  profile: IconProfile,
  recycle: IconRecycle,
  redo: IconRedo,
  reset: IconReset,
  robot: IconRobot,
  rotate: IconRotate,
  scale: IconScale,
  send: IconSend,
  shirt: IconShirt,
  shoes: IconShoes,
  shuffle: IconShuffle,
  speed: IconSpeed,
  stack: IconStack,
  store: IconStore,
  threeQuarterCircle: IconThreeQuarterCircle,
  tick: IconTick,
  undo: IconUndo,
  video: IconVideo
};
