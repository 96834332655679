import Image from '@components/website/Image';
import { BREAKPOINT_TABLET } from '@constants';
import { MediaVideoType } from '@types';
import cx from 'classnames';
import { useInView } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';

import styles from './styles.module.scss';

interface BackgroundVideoProps {
  className?: string;
  videoMedia: MediaVideoType;
  coverSizes?: string;
  autoPlay?: boolean;
  canPlay?: boolean;
}

const BackgroundVideo: React.FC<BackgroundVideoProps> = ({
  className,
  videoMedia: { src, cover, alt },
  coverSizes,
  autoPlay = true,
  canPlay = true
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const isInView = useInView(videoRef);

  const [isPreloading, setIsPreloading] = useState(true);
  const [hasHover, setHasHover] = useState(false);
  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_TABLET}px)`, false);
  const isPlaying = canPlay && isInView && (autoPlay || isNarrow || hasHover);

  useEffect(() => {
    if (isPreloading) return;

    if (isPlaying) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [isPlaying, isPreloading]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.readyState >= 1) {
      setIsPreloading(false);
    }
  }, []);

  const onLoadedMetadata = () => {
    setIsPreloading(false);
  };

  return (
    <div
      className={cx(styles.container, className)}
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
    >
      <video
        ref={videoRef}
        src={`${src}#t=0.1`}
        className={styles.video}
        loop
        muted
        playsInline
        preload="metadata"
        onLoadedMetadata={onLoadedMetadata}
      />

      {isPreloading && cover && (
        <Image
          className={styles.cover}
          src={cover}
          objectFit="cover"
          sizes={coverSizes}
          alt={alt ?? 'Cover image for video'}
        />
      )}
    </div>
  );
};

export default BackgroundVideo;
