import cx from 'classnames';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import styles from './styles.module.scss';

interface LinkProps extends NextLinkProps {
  'aria-label'?: string;
  children?: React.ReactNode | string;
  className?: string;
  id?: string;
  label?: string;
  onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement>;
  onFocus?: React.FocusEventHandler<HTMLAnchorElement>;
}

const Link = ({
  children,
  className,
  href,
  id,
  passHref,
  scroll,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  'aria-label': ariaLabel
}: LinkProps) => (
  <NextLink
    href={href}
    passHref={passHref}
    scroll={scroll}
    aria-label={ariaLabel}
    className={cx(styles.link, className)}
    id={id}
    onFocus={onFocus}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </NextLink>
);

export default Link;
