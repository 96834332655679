import cx from 'classnames';

import styles from './Columns.module.scss';

export const Columns: React.FC<{
  className?: string;
  as?: string | React.ElementType;
  children?: React.ReactNode;
}> = ({ children, className, as }) => {
  const Component = as ?? 'div';

  return (
    <Component className={cx(styles.columns, className)}>{children}</Component>
  );
};

export const Column: React.FC<{
  start?: number;
  span?: number;
  as?: string | React.ElementType;
  className?: string;
  children?: React.ReactNode;
}> = ({ children, className, start, span, as }) => {
  const Component = as ?? 'div';
  const startClass = `start-${start}`;
  const spanClass = `span-${span}`;

  return (
    <Component
      className={cx(
        styles.column,
        className,
        styles[startClass],
        styles[spanClass]
      )}
    >
      {children}
    </Component>
  );
};
