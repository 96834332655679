import cx from 'classnames';
import React from 'react';

import Icon, { IconSize } from '../Icon';
import styles from './styles.module.scss';
interface LoaderProps {
  className?: string;
  size?: IconSize;
}
const Loader = ({ className, ...props }: LoaderProps) => (
  <Icon
    className={cx(className, styles.loader)}
    name="threeQuarterCircle"
    {...props}
  />
);

export default React.memo(Loader);
