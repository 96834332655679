import { createClient } from '@sanity/preview-kit/client';

export const projectId = process.env.sanityProjectId || '';
export const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET || 'production';
export const apiVersion = new Date().toISOString().slice(0, 10);

export const client = createClient({
  projectId,
  dataset,
  apiVersion,
  useCdn: false,
  studioUrl: 'https://pioweb.sanity.studio'
});

export const getClient = (previewToken?: string) => {
  if (previewToken) {
    return client.withConfig({
      token: previewToken,
      useCdn: false,
      ignoreBrowserTokenWarning: true,
      perspective: 'previewDrafts'
    });
  }

  return client;
};
