import Script from 'next/script';

export interface Props {
  key?: string;
  id?: string;
}

export const CookieBanner = ({
  key = '73e67a63-dab8-4fb6-b490-b726de07b73e',
  id = 'Cookiebot'
}: Props) => (
  // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
  <Script
    id={id}
    data-blockingmode="auto"
    strategy="beforeInteractive"
    data-cbid={key}
    src="https://consent.cookiebot.com/uc.js"
    type="text/javascript"
  />
);

export const CookieDeclaration = ({
  key = '73e67a63-dab8-4fb6-b490-b726de07b73e',
  id = 'CookieDeclaration'
}: Props) => (
  <Script id={id} src={`https://consent.cookiebot.com/${key}/cd.js`} />
);
