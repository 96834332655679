import '../styles/reset.scss';
import '../styles/index.scss';

import { CookieBanner } from '@components/CookieBotBanner';
import { RocketPopup } from '@components/RocketPopup';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { FC, useEffect } from 'react';

import { akkurat, akkuratMono } from '../font';
import { GTM_ID, pageview, setOriginalLocation } from '../helpers/gtm';
import {
  setInitialPageViewPath,
  setSubsequentPageViewTracking
} from '../helpers/hubspot';

if (
  process.env.NEXT_PUBLIC_DEPLOY_ENV === 'test' &&
  typeof window !== 'undefined'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('../mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass'
  });
}

type ScriptsProps = {
  pageProps: {
    preview?: boolean;
  };
};

const Scripts: FC<ScriptsProps> = ({ pageProps }) => {
  // Skip any third-party scripts during tests and preview mode
  if (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'test' || pageProps?.preview) {
    return null;
  }

  return (
    <>
      {process.env.NEXT_PUBLIC_ENABLE_COOKIEBOT === 'true' && <CookieBanner />}
      {GTM_ID && (
        <Script
          id={GTM_ID}
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${GTM_ID}');
    `
          }}
        />
      )}
      {process.env.NEXT_PUBLIC_ENABLE_HUBSPOT_TRACKING === 'true' && (
        <Script
          src="//js-eu1.hs-scripts.com/24994191.js"
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
        />
      )}

      {process.env.NEXT_PUBLIC_ENABLE_ROCKET_POPUP === 'true' && (
        <RocketPopup />
      )}
    </>
  );
};

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    router.events.on('routeChangeComplete', setSubsequentPageViewTracking);
    return () => {
      router.events.off('routeChangeComplete', pageview);
      router.events.off('routeChangeComplete', setSubsequentPageViewTracking);
    };
  }, [router.events]);

  useEffect(() => {
    setInitialPageViewPath();
    setOriginalLocation(document);
  }, []);

  return (
    <>
      <Scripts {...pageProps} />
      <style jsx global>{`
        :root {
          --font-base: ${akkurat.style.fontFamily};
          --font-mono: ${akkuratMono.style.fontFamily};
        }
      `}</style>
      <Component {...pageProps} />
    </>
  );
};

export default App;
