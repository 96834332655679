import cx from 'classnames';

import styles from './styles.module.scss';

interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'label' | 'blockquote';
  color?: 'navy' | 'grey' | 'grey5' | 'zest3' | 'teal' | 'white';
}

const Title = ({
  as = 'h3',
  variant = 'h3',
  children,
  className,
  color,
  ...headingProps
}: TitleProps) => {
  const Component = as;
  return (
    <Component
      className={cx(
        styles.title,
        styles[variant],
        className,
        color && styles[color]
      )}
      {...headingProps}
    >
      {children}
    </Component>
  );
};

export default Title;
