import { http, HttpResponse } from 'msw';

const salesforce = [
  http.post('/api/builds', () =>
    HttpResponse.json({ id: 'some-uuid', message: 'created a grid' })
  ),
  http.post('/api/files', () =>
    HttpResponse.json({ message: 'Uploaded file successfully' })
  )
];

export default salesforce;
