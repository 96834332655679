import Icon, { IconTypes } from '@components/atoms/Icon';
import Link from '@components/atoms/Link';
import Loader from '@components/atoms/Loader';
import BackgroundVideo from '@components/BackgroundVideo';
import cx from 'classnames';
import React, { HTMLProps } from 'react';

import { BREAKPOINT_TABLET } from '../../constants';
import { MediaVideoType } from '../../types';
import styles from './styles.module.scss';

type ButtonVariant =
  | 'bigMain'
  | 'bigMainDark'
  | 'bigSecondary'
  | 'smallMain'
  | 'smallSecondary'
  | 'smallAutoStore'
  | 'bigSecondaryVideo'
  | 'link'
  | 'iconOnlyMain'
  | 'toggle'
  | 'subnav';

const getIconSize = (variant: ButtonVariant) => {
  switch (variant) {
    case 'bigMain':
    case 'bigMainDark':
    case 'bigSecondary':
      return 'm';
    case 'smallMain':
    case 'iconOnlyMain':
    case 'smallSecondary':
      return 's';
    default:
      return 'm';
  }
};

interface ButtonIconProps {
  variant: ButtonVariant;
  icon: IconTypes;
  isLoading: boolean;
}

const ButtonIcon = ({ icon, variant, isLoading }: ButtonIconProps) => {
  if (isLoading) {
    return <Loader size={getIconSize(variant)} />;
  }

  return <Icon name={icon} size={getIconSize(variant)} />;
};

interface BaseButtonProps {
  className?: string;
  disabled?: boolean;
  isActive?: boolean; // used for subnav/current link
  isLoading?: boolean; // used for async scenarios
  scroll?: boolean;
  icon?: IconTypes;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
  video?: MediaVideoType;
}

export type ButtonProps = BaseButtonProps &
  (
    | Omit<HTMLProps<HTMLButtonElement>, 'className' | 'size'>
    | Omit<HTMLProps<HTMLAnchorElement>, 'className' | 'type'>
  );

const Button = ({
  children,
  className,
  disabled = false,
  href,
  id,
  isLoading = false,
  isActive = false,
  scroll,
  icon = 'arrowRight',
  type = 'button',
  variant = 'bigMain',
  video,
  ...props
}: ButtonProps) => {
  const buttonStyle = cx(className, styles.button, {
    [styles[variant]]: true,
    [styles.disabled]: disabled && !isLoading,
    [styles.active]: isActive
  });
  const shouldRenderIcon = ![
    'link',
    'bigSecondaryVideo',
    'toggle',
    'subnav'
  ].includes(variant);
  const shouldRenderVideo = variant === 'bigSecondaryVideo' && !!video;

  if (href) {
    const anchorProps = props as HTMLProps<HTMLAnchorElement>;
    return (
      <Link
        href={href}
        id={id}
        scroll={scroll}
        passHref={href.includes('http')}
        className={buttonStyle}
        {...anchorProps}
      >
        {children}
        {shouldRenderIcon && (
          <ButtonIcon icon={icon} variant={variant} isLoading={isLoading} />
        )}
        {shouldRenderVideo && (
          <BackgroundVideo
            className={styles.video}
            videoMedia={video}
            coverSizes={`(min-width: ${BREAKPOINT_TABLET}px) 96px, 56px`}
            autoPlay
          />
        )}
      </Link>
    );
  }

  const buttonProps = props as HTMLProps<HTMLButtonElement>;
  return (
    <button
      className={buttonStyle}
      disabled={disabled}
      id={id}
      {...buttonProps}
      type={type}
    >
      {children}
      {shouldRenderIcon && (
        <ButtonIcon icon={icon} variant={variant} isLoading={isLoading} />
      )}
      {shouldRenderVideo && (
        <BackgroundVideo
          className={styles.video}
          videoMedia={video}
          coverSizes={`(min-width: ${BREAKPOINT_TABLET}px) 96px, 56px`}
          autoPlay
        />
      )}
    </button>
  );
};

export default Button;
