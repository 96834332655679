import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import cx from 'classnames';
import Img from 'next/image';
import { useNextSanityImage } from 'next-sanity-image';
import { useState } from 'react';

import { client } from '../../../sanity/sanity.client';
import styles from './Image.module.scss';

type ImageType = {
  alt?: string;
  className?: string;
  src: SanityImageSource | MediaImage | undefined;
  sizes?: string;
  objectFit?: 'cover' | 'contain';
};

const Image: React.FC<ImageType> = ({
  alt,
  className,
  src,
  sizes,
  objectFit
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imageProps = useNextSanityImage(client, src ?? '');

  return (
    <figure
      className={cx(styles.wrap, className, { [styles.loaded]: isLoaded })}
    >
      <Img
        loader={imageProps?.loader ?? undefined}
        src={imageProps?.src ?? ''}
        alt={alt ?? 'missing alt'}
        className={cx(
          'media',
          'transition-all',
          'duration-1250',
          'ease-out-quart'
        )}
        sizes={sizes}
        crossOrigin="anonymous"
        onLoad={() => setIsLoaded(true)}
        style={{ objectFit }}
        fill
      />
    </figure>
  );
};

export default Image;
