import { MerchantType } from '../types';

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID ?? '';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    google_tag_manager: Record<string, { dataLayer: { reset: () => void } }>;
  }
}

export const reset = () => {
  const gtm = window?.google_tag_manager?.[GTM_ID];
  if (gtm) {
    gtm.dataLayer.reset();
  }
};

export const setOriginalLocation = (document: Document) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    originalLocation:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search
  });
};

export const pageview = (url: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'page_view',
    url,
    title: document.title
  });
};

export const setVariant = (variant: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ab_test',
    variant: variant
  });
};

export const buttonClick = (eventName: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName
  });
};

export const newsletterSubmitted = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'formSubmission',
    formName: 'newsletterForm',
    leadType: 'soft'
  });
};

export const contactFormSubmitted = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'formSubmission',
    formName: 'contactForm',
    pageType: 'contact',
    leadType: 'hard'
  });
};

export const miniCalculatorSubmitted = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'formSubmission',
    formName: 'miniCalculatorForm',
    leadType: 'hard'
  });
};

type GtmCustomerField = 'merchantType' | 'country' | 'industry';

export const customerFieldChange = (
  field: GtmCustomerField,
  value: unknown
) => {
  window.dataLayer = window.dataLayer || [];
  if (field === 'merchantType') {
    window.dataLayer.push({
      [field]: value === 'd2c' ? 'D2C' : 'Reseller'
    });
  } else {
    window.dataLayer.push({
      [field]: value
    });
  }
};

export const roiFormSubmitted = (
  merchantType: MerchantType,
  country: string,
  industry: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'formSubmission',
    formName: 'contactFormCalc',
    merchantType: merchantType === 'd2c' ? 'D2C' : 'Reseller',
    country,
    industry
  });
};
