import cx from 'classnames';

import styles from './styles.module.scss';

export interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactNode | undefined | any;
  as?: 'span' | 'p' | 'div';
  variant?: 'b1' | 'b2' | 'b3' | 'label' | 'navLink';
  color?:
    | 'navy'
    | 'grey'
    | 'grey2'
    | 'grey5'
    | 'zest3'
    | 'teal'
    | 'white'
    | 'error';
}

const Text = ({
  children,
  className,
  as: Component = 'p',
  variant = 'b1',
  color = 'navy',
  ...textProps
}: TextProps) => (
  <Component
    className={cx(styles.text, styles[variant], styles[color], className)}
    {...textProps}
  >
    {children}
  </Component>
);

export default Text;
