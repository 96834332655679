import 'react-spring-bottom-sheet/dist/style.css';

import Icon from '@components/atoms/Icon';
import Text from '@components/atoms/Text';
import Title from '@components/atoms/Title';
import { Column, Columns } from '@components/website/Columns';
import { BREAKPOINT_TABLET } from '@constants';
import * as gtm from '@helpers/gtm';
import useRocketPopupStore from '@hooks/store/useRocketPopupStore';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/legacy/image';
import { useEffect, useRef, useState } from 'react';
import { Check } from 'react-feather';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useMedia } from 'react-use';

import profile from '../../assets/images/Craig.png';
import Button from '../Button';
import styles from './RocketPopup.module.scss';

const checklist = [
  'Bespoke analysis of your warehouse',
  'How to make your warehouse more efficient',
  'How to reduce human error & improve stock management',
  'How to introduce warehouse automation to become super-efficient'
];

const POPUP_TIMEOUT = 6000;

interface Props {
  isVisible?: boolean;
  children?: React.ReactNode;
}

const RocketPopup = ({ isVisible }: Props) => {
  const { closed, setClosed } = useRocketPopupStore(state => state);
  const [open, setOpen] = useState(isVisible);

  const handleClose = () => {
    setClosed(true);
    setOpen(false);
  };

  const handleBookNow = () => {
    gtm.buttonClick('warehouseEfficiencyBookNowClick');
    handleClose();
    window.open(
      'https://content.pio.com/free-warehouse-efficiency-strategy-call'
    );
  };

  useEffect(() => {
    if (closed) return;
    setTimeout(() => {
      setOpen(true);
    }, POPUP_TIMEOUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNarrow = useMedia(`(max-width: ${BREAKPOINT_TABLET}px)`, false);

  return !isNarrow ? (
    <DesktopDialog isVisible={open}>
      <Content handleClose={handleClose} handleBookNow={handleBookNow} />
    </DesktopDialog>
  ) : (
    <MobileDialog isVisible={open} handleClose={handleClose}>
      <Content
        isNarrow
        handleClose={handleClose}
        handleBookNow={handleBookNow}
      />
    </MobileDialog>
  );
};

interface ContentProps {
  isNarrow?: boolean;
  handleClose?: () => void;
  handleBookNow?: () => void;
}

const Content = ({ isNarrow, handleClose, handleBookNow }: ContentProps) => {
  return (
    <>
      {!isNarrow && (
        <button
          onClick={handleClose}
          className={styles.buttonClose}
          aria-label="Close rocket popup"
        >
          <Icon name="close" size="xs" fill="zest" />
        </button>
      )}

      <Columns>
        <Column start={1} span={6} className={styles.leftColumn}>
          <div className={styles.topRow}>
            <Icon name="pio" size="s" fill="zest" />
            {isNarrow && (
              <Button
                className={styles.dismissButton}
                onClick={handleClose}
                variant="link"
              >
                Not Now
              </Button>
            )}
          </div>

          <Title className={styles.title} variant="h1">
            FREE•WAREHOUSE• EFFICIENCY• STRATEGY•CALL
          </Title>
          <Columns>
            <Column start={1} span={4}>
              <div className={styles.image}>
                <Image
                  src={profile}
                  objectFit="cover"
                  layout="fill"
                  alt="Avator of Craig Langdon - Warehouse Efficiency expert"
                />
              </div>
            </Column>
            <Column start={5} span={9} className={styles.profileWrapper}>
              <Text variant="b2" color="zest3">
                with Craig Langdon
              </Text>
              <Text variant="b2" color="white">
                Warehouse Efficiency expert
              </Text>
              <Button
                onClick={handleBookNow}
                className={styles.submitButton}
                aria-label="Book now"
                variant="smallMain"
              >
                BOOK NOW
              </Button>
            </Column>
          </Columns>
        </Column>
        <Column start={7} span={6}>
          <ul className={styles.list}>
            {checklist.map((item: string, index: number) => (
              <li key={index} className={styles.listItem}>
                <Check size={12} strokeWidth={3} />
                <Text variant="b2">{item}</Text>
              </li>
            ))}
          </ul>
        </Column>
      </Columns>
    </>
  );
};

const DesktopDialog: React.FC<Props> = ({ isVisible, children }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={styles.dialog}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -100, opacity: 0 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface MobileDialogProps extends Props, ContentProps {}

const MobileDialog: React.FC<MobileDialogProps> = ({
  isVisible,
  handleClose,
  children
}) => {
  const focusRef = useRef<HTMLDivElement>(null);
  return (
    <BottomSheet
      className={styles.sheet}
      open={isVisible ?? false}
      onDismiss={handleClose}
      initialFocusRef={focusRef}
      blocking={false}
      snapPoints={({ maxHeight }) => [maxHeight - 200]}
    >
      <div ref={focusRef} className={styles.panel}>
        {children}
      </div>
    </BottomSheet>
  );
};

export default RocketPopup;
