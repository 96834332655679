/**
 * The methods allowed by HubSpot tracking code
 */
export type MethodParam =
  | 'setPath'
  | 'trackPageView'
  | 'identify'
  | 'trackEvent'
  | 'revokeCookieConsent'
  | 'addPrivacyConsentListener'
  | 'doNotTrack'
  | 'addIdentityListener'
  | 'setContentType'
  | 'refreshPageHandlers';

// See: https://github.com/kelvinmaues/react-hubspot-tracking-code-hook/blob/main/src/useTrackingCode/index.tsx
// and https://github.com/kelvinmaues/react-hubspot-tracking-code-hook/blob/main/src/models/index.ts
// eslint-disable-next-line @typescript-eslint/ban-types
export type CallParams = [MethodParam, (string | object)?];

declare global {
  interface Window {
    _hsq: { push: (callParam: CallParams) => void };
  }
}

// The functions below are used in _app.tsx and run on initial render and then whenever the route changes, to automatically
// record a page view. We do this because we manually want to track page views without having to reload the HubSpot tracking code each time.

// Set up the path for the initial page view to track initial page
export const setInitialPageViewPath = () => {
  const _hsq = window._hsq;
  _hsq && _hsq.push(['setPath', '/']);
};

// Set up tracking on subsequent page views to manually track other pages
export const setSubsequentPageViewTracking = (path: string) => {
  const _hsq = window._hsq;
  _hsq && _hsq.push(['setPath', path]);
  _hsq && _hsq.push(['trackPageView']);
};
